import React, { useState, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MainLayout from "./Layout";
import './Form.css'
import { Throw } from "./Rdminethrow";
import Divisionbar from "./Divisionbar";
import { CircularProgress } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



function Forms() {

    const [selectedImage, setSelectedImage] = useState([]);
    const [formtype, setFormtype] = useState([{}]);
    const [user, setUser] = useState()
    const [showAlert, setShowAlert] = useState(false);
    const [accordions, setAccordions] = useState([1]);

    const [counter, setCounter] = useState(2);

    const addAccordion = () => {
        setAccordions([...accordions, counter]);
        setCounter(counter + 1);
    };

    const removeAccordion = (id) => {
        setAccordions(accordions.filter((accordion) => accordion !== id));
    };


    useEffect(() => {
        const getType = async () => {
            try {
                const asfa = await AsyncStorage.getItem('@formulario')
                const usuariosalvo = await AsyncStorage.getItem('@Userchamados') ?? false

                //setUser(JSON.parse(usuariosalvo))
                if (usuariosalvo && usuariosalvo != "undefined") {
                    setUser(JSON.parse(usuariosalvo));
                } else {
                    console.log("Usuário não encontrado");
                }

                //console.log(user)

                //setFormtype(JSON.parse(asfa))
                if (asfa && asfa != "undefined") {
                    setFormtype(JSON.parse(asfa));
                } else {
                    console.log("Formulário não encontrado");
                }

            } catch (e){
                console.log(e)
            }

        }


        getType()
    }, [])

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        // var domain = data.
        console.log(typeof (data.formtype))
        for (var i in data.formtype) {
            if (data.formtype[i].nome === "Email coorporativo") {
                var domain = "casal.al.gov.br";
                var pattern = new RegExp("^[\\w.]+@" + domain.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + "$")
                console.log(data.formtype[i].tipo)

                var table = ""

                if (data.input11) {
                    const rows = accordions.map((accordion) => {
                        const inputs = [];
                        for (let i = 1; i <= 5; i++) {
                            const key = `input${accordion}${i}`;
                            if (data[key]) {
                                inputs.push(data[key]);
                            }
                        }
                        return `${i > 0 ? '\n' : ''} |${accordion}| ${inputs.join(' | ')} |`;
                    });

                    const header = '|*ITEM*|*DESCRIÇÃO*|*LOCAL DO BEM (CENTRO DE CUSTO)*|*TOMBAMENTO*|*NÚMERO DE SÉRIE*|';
                    const formattedValues = [header, ...rows].join('');

                    table = formattedValues
                }
                console.log(table)

                if (pattern.test(data.formtype[i].tipo)) {

                    setShowAlert(true);

                    console.log(data, formtype[0], selectedImage)

                    Throw(data, formtype[0], selectedImage, table).then((e => {

                        setShowAlert(false)


                        if (e === "Sucesso") {
                            alert("Chamado criado")
                        }
                        else {
                            alert("Algo deu errado")
                        }

                    }))
                    // alert("Email inserido é da Casal")

                }
                else {
                    alert("Email inserido não é da Casal")
                }
            }


        }




    };

    const handleImageChange = (event) => {
        // const file = event.target.files[0];
        // setSelectedImage(URL.createObjectURL(file));
        const files = event.target.files
        const imagesarray = [];

        for (var i = 0; i < files.length; i++) {
            const reader = new FileReader()
            console.log(files[0].name)
            reader.onload = (e) => {
                imagesarray.push({ "imagecode": e.target.result, "extension": files[0].name.substring(files[0].name.length - 3), "name": files[0].name.substring(0, files[0].name.length - 3) })
                if (imagesarray.length === files.length) {
                    setSelectedImage(prevItems => [...prevItems, imagesarray[0]])
                }
            }

            reader.readAsDataURL(files[i])
        }
    };

    return (
        <div>
            <MainLayout></MainLayout>
            <div className="tela">
                <div className="bgfitter">
                    <div className="mainform">


                        <form style={{ display: "flex", flexDirection: 'column', marginLeft: '10%', marginRight: "10%" }} onSubmit={handleSubmit(onSubmit)}>
                            <h1 style={{ display: "flex", justifyContent: "center" }}>{formtype[0].project_name}</h1>
                            <Divisionbar></Divisionbar>
                            {formtype.map((row, index) => (
                                <div key={index} style={{ marginLeft: "10%" }}>
                                    <label style={{ display: "block", marginTop: "5px" }}>{formtype[index].nome}</label>
                                    <input className="inserir" style={{ display: "none" }} {...register(`formtype[${index}].nome`)} value={formtype[index].nome} />
                                    {formtype[index].tipo === "input" && !String(formtype[index].nome).toUpperCase().includes("DATA") && formtype[index].nome !== "Nome completo" && formtype[index].nome !== "Número da matricula" &&
                                        formtype[index].nome !== "Cpf" && formtype[index].nome !== "Email coorporativo" && formtype[index].nome !== "Data de nascimento para o novo perfil" && formtype[index].tipo !== "file" &&
                                        (
                                            <div>
                                                <input placeholder={formtype[index].required ? "Campo obrigatório" : ""} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                            </div>


                                        )}

                                    {formtype[index].tipo === "file" && (
                                        <input onChange={handleImageChange} className="inserir" type="file" multiple />

                                    )}

                                    {formtype[index].tipo === "textarea" && (
                                        <textarea className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />
                                    )}


                                    {formtype[index].tipo === "holder" && (
                                        <h2 style={{ width: "630px" }} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })}>{formtype[index].text}</h2>

                                    )}

                                    {formtype[index].tipo === "simpletext" && (
                                        <p>{formtype[index].text}</p>

                                    )}




                                    {formtype[index].tipo === "select" && formtype[index].drop === "predio" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> ------- </option>
                                            <option value="GETIN/GEROC">GETIN/GEROC</option>
                                            <option value="CALLCENTER">CALLCENTER</option>
                                            <option value="SEDE">SEDE</option>
                                            <option value="COMPLEXO BENEDITO BENTES">COMPLEXO BENEDITO BENTES</option>
                                            <option value="Complexo Farol">Complexo Farol</option>
                                            <option value="UN AGRESTE">UN AGRESTE</option>
                                            <option value="UN LESTE">UN LESTE</option>
                                            <option value="UN SERRANA">UN SERRANA</option>
                                            <option value="UN SERTÃO">UN SERTÃO</option>
                                            <option value="UN B LEITEIRA">UN B LEITEIRA</option>


                                        </select>

                                    )}

                                    {formtype[index].tipo === "select" && formtype[index].drop === "lotacao" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> ------- </option>
                                            <option style={{ fontWeight: "bolder" }} value="DIRETORIA DA PRESIDENCIA">DIRETORIA DA PRESIDENCIA</option>
                                            <option style={{ fontWeight: "bolder" }} value="ASCOM">ASCOM</option>
                                            <option value="GAPRE">GAPRE</option>
                                            <option value="ASLIC">ASLIC</option>
                                            <option value="SUNES">SUNES</option>
                                            <option value="GEENE">GEENE</option>
                                            <option style={{ fontWeight: "bolder" }} value="GETNE">GETNE</option>
                                            <option value="SUPMOD">SUPMOD</option>
                                            <option style={{ fontWeight: "bolder" }} value="SUDEO">SUDEO</option>
                                            <option value="GEGOT">GEGOT</option>
                                            <option value="GEQUAI">GEQUAI</option>
                                            <option value="GEPLAN">GEPLAN</option>
                                            <option value="GETIN">GETIN</option>
                                            <option style={{ fontWeight: "bolder" }} value="SUJUR">SUJUR</option>
                                            <option value="GEJUR">GEJUR</option>
                                            <option value="GECOT">GECOT</option>                                            
                                            <option style={{ fontWeight: "bolder" }} value="VPC">VPC</option>
                                            <option style={{ fontWeight: "bolder" }} value="SULOS">SULOS</option>
                                            <option value="GESEA">GESEA</option>
                                            <option value="GEPAT">GEPAT</option>
                                            <option value="GESUP">GESUP</option>                                            
                                            <option style={{ fontWeight: "bolder" }} value="SUFIC">SUFIC</option>
                                            <option value="GEFIN">GEFIN</option>
                                            <option value="GEEOC">GEEOC</option>
                                            <option value="GECONT">GECONT</option>
                                            <option value="GEROC">GEROC</option>
                                            <option value="GECAS">GECAS</option>
                                            <option style={{ fontWeight: "bolder" }} value="SURHU">SURHU</option>
                                            <option value="GEDEP">GEDEP</option>
                                            <option value="GESMET">GESMET</option>
                                            <option style={{ fontWeight: "bolder" }} value="VPE">VPE</option>
                                            <option style={{ fontWeight: "bolder" }} value="SUENG">SUENG</option>
                                            <option value="GEPRO">GEPRO</option>
                                            <option value="GEOBS">GEOBS</option>
                                            <option style={{ fontWeight: "bolder" }} value="SUMAQ">SUMAQ</option>
                                            <option value="GECAM">GECAM</option>
                                            <option value="GEQPRO">GEQPRO</option>
                                            <option style={{ fontWeight: "bolder" }} value="VPO">VPO</option>
                                            <option style={{ fontWeight: "bolder" }} value="RMM">RMM</option>
                                            <option value="GEPROM">GEPROM</option>
                                            <option value="GEPOR 1">GEPOR 1</option>
                                            <option style={{ fontWeight: "bolder" }} value="SAS">SAS</option>
                                            <option value="GEPOR 2">GEPOR 2</option>
                                            <option value="GEOPE">GEOPE</option>
                                            <option style={{ fontWeight: "bolder" }} value="SAS">ZML</option>
                                            <option value="GEPOR 3">GEPOR 3</option>
                                            <option value="GEPROC">GEPROC</option>                                           
                                            <option style={{ fontWeight: "bolder" }} value="SUTEC">SUTEC</option>
                                            <option value="GEDOP">GEDOP</option>
                                            <option value="GEMEM">GEMEM</option>
                                            <option style={{ fontWeight: "bolder" }} value="SUEEO">SUEEO</option>
                                            <option value="GEFOP">GEFOP</option>
                                            <option value="GEFEN">GEFEN</option>
                                            <option style={{ fontWeight: "bolder" }} value="SUCAS">SUCAS</option>
                                            <option value="GEFOP">GEPOM</option>
                                            <option value="GEFEN">GECAF</option>
                                            
                                            <option value="GEMEM" disabled>--UNIDADES REGIONAIS--</option>
                                            <option style={{ fontWeight: "bolder" }} value="UR AGRESTE">UR AGRESTE</option>
                                            <option style={{ fontWeight: "bolder" }} value="UR LESTE">UR LESTE</option>
                                            <option style={{ fontWeight: "bolder" }} value="UR SERRANA">UR SERRANA</option>
                                            <option style={{ fontWeight: "bolder" }} value="UR SERTÃO">UR SERTÃO</option>
                                            <option style={{ fontWeight: "bolder" }} value="UR BACIA LEITEIRA">UR BACIA LEITEIRA</option>
                                        </select>

                                    )}




                                    {formtype[index].tipo === "select" && formtype[index].drop === "hcm" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> ------- </option>
                                            <option value="ERP">ERP</option>
                                            <option value="HCM">HCM</option>



                                        </select>

                                    )}

                                    {formtype[index].tipo === "select" && formtype[index].drop === "estacao" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> ------- </option>
                                            <option value="CAPITAÇÃO">CAPITAÇÃO</option>
                                            <option value="ESTAÇÃO ELEVATORIA ESGOTO">ESTAÇÃO ELEVATORIA ESGOTO</option>
                                            <option value="ESTAÇÃO ELEVATORIA ÁGUA BRUTA">ESTAÇÃO ELEVATORIA ÁGUA BRUTA</option>
                                            <option value="ESTAÇÃO ELEVATORIA ÁGUA TRATADA">ESTAÇÃO ELEVATORIA ÁGUA TRATADA</option>
                                            <option value="ESTAÇÃO TRATAMENTO DE ÁGUA">ESTAÇÃO TRATAMENTO DE ÁGUA</option>



                                        </select>

                                    )}

                                    {formtype[index].tipo === "select" && formtype[index].drop === "Servico" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Serviço </option>
                                            <option value="NOVO PERFIl">NOVO PERFIl</option>
                                            <option value="ALTERAÇÃO DE PERFIL">ALTERAÇÃO DE PERFIL</option>
                                            <option value="INATIVAR PERFIL">INATIVAR PERFIL</option>
                                            <option value="OUTROS">OUTROS</option>



                                        </select>

                                    )}

                                    {formtype[index].tipo === "select" && formtype[index].drop === "referencias" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar o Mês </option>
                                            <option value="JANEIRO">JANEIRO</option>
                                            <option value="FEVEREIRO">FEVEREIRO</option>
                                            <option value="MARÇO">MARÇO</option>
                                            <option value="ABRIL">ABRIL</option>
                                            <option value="MAIO">MAIO</option>
                                            <option value="JUNHO">JUNHO</option>
                                            <option value="JULHO">JULHO</option>
                                            <option value="AGOSTO">AGOSTO</option>
                                            <option value="SETEMBRO">SETEMBRO</option>
                                            <option value="OUTUBRO">OUTUBRO</option>
                                            <option value="NOVEMBRO">NOVEMBRO</option>
                                            <option value="DEZEMBRO">DEZEMBRO</option>


                                        </select>

                                    )}

                                    {formtype[index].tipo === "documento" && (
                                        <div></div>
                                    )}


                                    {formtype[index].tipo === "input" && formtype[index].nome === "Nome completo" && (
                                        <div>
                                            <input value={user.display_name} placeholder={formtype[index].required ? "Campo obrigatório" : ""} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                        </div>
                                    )}

                                    {formtype[index].tipo === "input" && formtype[index].nome === "Número da matricula" && (
                                        <div>
                                            <input value={user.user_login} placeholder={formtype[index].required ? "Campo obrigatório" : ""} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                        </div>
                                    )}

                                    {formtype[index].tipo === "input" && formtype[index].nome === "Cpf" && (
                                        <div>
                                            <input value={user.user_pass} placeholder={formtype[index].required ? "Campo obrigatório" : ""} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                        </div>
                                    )}


                                    {formtype[index].tipo === "input" && formtype[index].nome === "Email coorporativo" && (
                                        <div>
                                            <input value={user.email} placeholder={formtype[index].required ? "Campo obrigatório" : ""} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                        </div>
                                    )}




                                    {formtype[index].tipo === "columncheckbox" && (
                                        <div>
                                            <input style={{ width: "40px" }} type="checkbox" className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                        </div>
                                    )}





                                    {formtype[index].tipo === "input" && String(formtype[index].nome).toUpperCase().includes("DATA") && (
                                        <div>
                                            <input type="date" placeholder={formtype[index].required ? "Campo obrigatório" : ""} className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} />

                                        </div>
                                    )}

                                    {formtype[index].tipo === "select" && formtype[index].drop === "Servicos Gsan" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Serviço </option>
                                            <option value="NOVO PERFIL">NOVO PERFIL</option>
                                            <option value="ALTERAÇÃO DE PERFIL">ALTERAÇÃO DE PERFIL</option>
                                            <option value="CANCELAMENTOS">CANCELAMENTOS</option>
                                            <option value="ORDEM DE SERVICO">ORDEM DE SERVICO</option>
                                            <option value="OUTROS">OUTROS</option>



                                        </select>

                                    )}

                                    {formtype[index].tipo === "select" && formtype[index].drop === "Servicos SUPMIC" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Serviço </option>
                                            <option value="CADASTRO DE HIDRÔMETRO NO GSAN">CADASTRO DE HIDRÔMETRO NO GSAN</option>
                                            <option value="DISPONIBILIZAR O HIDRÔMETRO NO GSAN<L">DISPONIBILIZAR O HIDRÔMETRO NO GSAN</option>
                                            <option value="TRAMITAR R.A.">TRAMITAR R.A.</option>
                                            <option value="LAUDO TÉCNICO">LAUDO TÉCNICO</option>
                                            <option value="HIDRÔMETRO VIOLADO PARA COMPROVAÇÃO JURÍDICA">HIDRÔMETRO VIOLADO PARA COMPROVAÇÃO JURÍDICA</option>
                                            <option value="NOVOS HIDRÔMETROS (IMPLANTAR / SUBISTITUIR)">NOVOS HIDRÔMETROS (IMPLANTAR / SUBISTITUIR)</option>
                                            <option value="CONEXÕES E MATERIAIS">CONEXÕES E MATERIAIS</option>



                                        </select>

                                    )}


                                    {formtype[index].tipo === "select" && formtype[index].drop === "nivelacademico" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Nível </option>
                                            <option value="TÉCNICO">TÉCNICO</option>
                                            <option value="GRADUAÇÃO">GRADUAÇÃO</option>
                                            <option value="PÓS-GRADUAÇÃO">PÓS-GRADUAÇÃO</option>



                                        </select>

                                    )}


                                    {formtype[index].tipo === "select" && formtype[index].drop === "Servicos GESEA" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Serviço </option>
                                            <option value="CAPINAGEM DE POÇO E ESTAÇÕES">CAPINAGEM DE POÇO E ESTAÇÕES</option>
                                            <option value="MANUTENÇÃO PREDIAL">MANUTENÇÃO PREDIAL</option>
                                            <option value="SERVIÇOS ELÉTRICOS">SERVIÇOS ELÉTRICOS</option>
                                            <option value="SERVIÇOS DE MACENARIA">SERVIÇOS DE MACENARIA</option>




                                        </select>

                                    )}


                                    {formtype[index].tipo === "select" && formtype[index].drop === "manutencao veiculos" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Manutenção </option>
                                            <option value="PREVENTIVA">PREVENTIVA</option>
                                            <option value="CORRETIVA">CORRETIVA</option>





                                        </select>

                                    )}


                            {formtype[index].tipo === "select" && formtype[index].drop === "cipa" && (
                                        <select className="inserir" {...register(`formtype[${index}].tipo`, { required: formtype[index].required })} >
                                            <option> Selecionar Serviço </option>
                                            <option value="PREENCHIMENTO DE RIAT">PREENCHIMENTO DE RIAT</option>
                                            <option value="RECLAMAÇÕES">RECLAMAÇÕES</option>
                                            <option value="SOLICITAÇÃO DE VISTORIA">SOLICITAÇÃO DE VISTORIA</option>
                                            <option value="SUGESTÕES">SUGESTÕES</option>





                                        </select>

                                    )}



                                    {formtype[index].tipo === "accordion suptbes" && (
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>{formtype[index].text}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p>Anexar Imagem</p>
                                                <input onChange={handleImageChange} className="inserir" type="file" multiple />
                                            </AccordionDetails>
                                        </Accordion>



                                    )}


                                    {formtype[index].tipo === "accordion gerad devolucao" && (
                                        <div>

                                            {accordions.map((accordion, index) => (
                                                <div>
                                                    <Accordion key={accordion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index + 1}-content`}
                                                            id={`panel${index + 1}-header`}
                                                        >
                                                            <Typography>{`Bem ${index + 1}`}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography component="div">
                                                                <div>
                                                                    <label>Descrição do bem</label>
                                                                    <br></br>
                                                                    <input
                                                                        className="inserir"
                                                                        {...register(`input${accordion}1`, { required: true })}
                                                                        placeholder={`Exemplo: Cadeora de escritório`}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Local do bem (centro de custo)</label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}2`, { required: true })}

                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Tombamento *(Campo obrigatório)</label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}3`, { required: true })}

                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Número de série *(Campo obrigatório)</label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}4`, { required: true })}

                                                                    />
                                                                </div>


                                                                <input onChange={handleImageChange} className="inserir" type="file" multiple />
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <button type="button" onClick={() => removeAccordion(accordion)}>Remover bem</button>
                                                </div>
                                            ))}

                                            <button type="button" onClick={addAccordion}>Adicionar bem </button>
                                        </div>


                                    )}




                                    {formtype[index].tipo === "accordion gerad solicitar" && (
                                        <div style={{display: "flex", alignItems: "center", flexDirection:"column"}}>

                                            {accordions.map((accordion, index) => (
                                                <div>
                                                    <Accordion key={accordion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index + 1}-content`}
                                                            id={`panel${index + 1}-header`}
                                                        >
                                                            <Typography>{`Bem ${index + 1}`}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography component="div">
                                                                <div>
                                                                    <label>Descrição do bem</label>
                                                                    <br></br>
                                                                    <input
                                                                        className="inserir"
                                                                        {...register(`input${accordion}1`, { required: true })}
                                                                        placeholder={`Exemplo: Cadeora de escritório`}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Local do bem(Centro de custo)</label>
                                                                    <br></br>
                                                                    <input
                                                                        className="inserir"
                                                                        {...register(`input${accordion}2`, { required: true })}
                                                                        
                                                                    />
                                                                </div>

                                                             
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <button type="button" onClick={() => removeAccordion(accordion)}>Remover bem</button>
                                                </div>
                                            ))}

                                            <button type="button"  onClick={addAccordion}>Adicionar bem </button>
                                        </div>


                                    )}

                                    {formtype[index].tipo === "accordion gerad transferir" && (
                                        <div>

                                            {accordions.map((accordion, index) => (
                                                <div>
                                                    <Accordion key={accordion}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index + 1}-content`}
                                                            id={`panel${index + 1}-header`}
                                                        >
                                                            <Typography>{`Bem ${index + 1}`}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography component="div">
                                                                <div>
                                                                    <label>Descrição do bem</label>
                                                                    <br></br>
                                                                    <input
                                                                        className="inserir"
                                                                        {...register(`input${accordion}1`, { required: true })}
                                                                        placeholder={`Exemplo: Cadeora de escritório`}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Centro de custos de origem</label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}2`, { required: true })}

                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Centro de custos de destino</label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}3`, { required: true })}

                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label>Tombamento</label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}4`, { required: true })}

                                                                    />
                                                                </div>

                                                                <div>
                                                                    <label>Número de série </label>
                                                                    <br></br>
                                                                    <input className="inserir"
                                                                        {...register(`input${accordion}4`, { required: true })}

                                                                    />
                                                                </div>


                                                                <input onChange={handleImageChange} className="inserir" type="file" multiple />
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <button type="button" onClick={() => removeAccordion(accordion)}>Remover bem</button>
                                                </div>
                                            ))}

                                            <button type="button" onClick={addAccordion}>Adicionar bem </button>
                                        </div>


                                    )}




                                </div>




                            ))}






                            {showAlert && <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "30px" }}><CircularProgress /> <br /> <p>ABRINDO CHAMADO</p> </div>}
                            <button className="btsmt" type="submit">ENVIAR</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Forms;
